<template>
  <div id="page-user-view">
    <vs-alert
      color="danger"
      title="User Not Found"
      :active.sync="venue_not_found"
    >
      <span>Venue with id: {{ $route.params.id }} not found. </span>
    </vs-alert>

    <div id="user-data" v-if="venueDetail">
      <vx-card title="Venue" class="mb-base">
        <!-- Avatar -->
        <div class="vx-row">
          <!-- Avatar Col -->
          <div class="vx-col" id="avatar-col">
            <div class="img-container mb-4">
              <img :src="(typeof venueDetail.photo[0] === 'string') ? venueDetail.photo[0] : defaultAvatar" alt="venue" class="rounded w-full"/>
            </div>
          </div>

          <!-- Information - Col 1 -->
          <div class="vx-col flex-1" id="account-info-col-1">
            <table>
              <tr>
                <td class="font-semibold">Name</td>
                <td>{{ venueDetail.name }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Contact</td>
                <td>{{ venueDetail.contact }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Location</td>
                <td>{{ venueDetail.location.country }}</td>
              </tr>
            </table>
          </div>

        </div>
      </vx-card>

      <vx-card title="Category" class="mb-base">
        <!-- Avatar -->
        <div class="vx-row">
          <!-- Avatar Col -->
          <div class="vx-col" id="avatar-col">
            <div class="img-container mb-4">
              <img :src="(venueDetail.category.icon !=='') ? venueDetail.category.icon : defaultAvatar" alt="venue" class="rounded w-full"/>
            </div>
          </div>

          <!-- Information - Col 1 -->
          <div class="vx-col flex-1" id="account-info-col-1">
            <table>
              <tr>
                <td class="font-semibold">Name</td>
                <td>{{ venueDetail.category.name }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Slug</td>
                <td>{{ venueDetail.category.slug }}</td>
              </tr>
            </table>
          </div>

        </div>
      </vx-card>

    </div>
  </div>
</template>

<script>
import {reportMixin} from "../../mixins/reportMixin";

export default {
  name: "VenueDetail",
  mixins: [reportMixin],
  data: () => ({
    venueDetail: null,
    venue_not_found: false,
    defaultAvatar:'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png'
  }),
  methods: {
    venueData(id) {
      let _vue = this;
      this.getVenueDetail(id).then((res) => {
        _vue.venueDetail = res;
      });
    }
  },
  created() {
    let venueId = this.$route.params.id;
    this.venueData(venueId);
  },
};
</script>
<style lang="scss">
#avatar-col {
  width: 10rem;
}

#page-user-view {
  table {
    td {
      vertical-align: top;
      min-width: 140px;
      padding-bottom: 0.8rem;
      word-break: break-all;
    }

    &:not(.permissions-table) {
      td {
        @media screen and (max-width: 370px) {
          display: block;
        }
      }
    }
  }
}

@media screen and (min-width: 1201px) and (max-width: 1211px),
only screen and (min-width: 636px) and (max-width: 991px) {
  #account-info-col-1 {
    width: calc(100% - 12rem) !important;
  }
}
</style>
